<template>
  <div class="strength-input">
    <label class="strength-label" for="strength-input">
      Fragrance Load:
      <span class="strength-label-value">{{ modelValue }}%</span>
    </label>
    <div class="strength-slider">
      <input
        id="strength-input"
        class="strength-slider-input"
        :min="MIN_LOAD"
        :max="maxLoad"
        type="range"
        :step="step"
        :value="modelValue"
        @input="onChange"
      />
      <div class="strength-slider-infos">
        <span class="strength-indicator">
          <strong>{{ `${MIN_LOAD}%` }} Light </strong>
        </span>
        <span class="strength-indicator recommend">
          <strong>{{ `${recommend}%` }} Recommended</strong>
        </span>
        <span class="strength-indicator">
          <strong>{{ `${maxLoad}%` }} Strong</strong>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const emit = defineEmits<{
    "update:modelValue": [value: string]
  }>()

  const MAX_LOAD = 12
  const MIN_LOAD = 3

  withDefaults(
    defineProps<{
      modelValue: string
      recommend?: number
      step?: number | string
      maxLoad?: number
    }>(),
    {
      step: 0.5,
      maxLoad: MAX_LOAD,
      recommend: 6,
    },
  )

  const onChange = (e: Event) => {
    const value = parseFloat((e.target as HTMLInputElement).value)
    emit("update:modelValue", value.toString())
  }
</script>

<style scoped lang="scss">
  .strength-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    margin-bottom: $space-m;
    .strength-slider {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .strength-slider-input {
    $knob-size: 30px;
    $knob-color: $blue-600;
    $track-height: 10px;

    margin-bottom: $base-spacing * 4;

    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    height: $track-height;
    border-radius: $border-radius;
    background: $gray-200;

    &::-moz-range-progress {
      height: $track-height;
      border-radius: $border-radius;

      background: $blue-500;
    }

    &::-webkit-slider-thumb {
      position: relative;
      -webkit-appearance: none;
      appearance: none;
      width: $knob-size;
      height: $knob-size;
      border-radius: 50%;
      background: $knob-color;
      cursor: pointer;
      box-shadow: $shadow;
    }

    &::-moz-range-thumb {
      width: $knob-size;
      height: $knob-size;
      border-radius: 50%;
      background: $knob-color;
      cursor: pointer;
    }
  }

  .strength-slider-infos {
    position: relative;
    display: flex;
    width: 100%;
    .strength-indicator {
      display: flex;
      flex: 1;
      flex-direction: column;
      white-space: nowrap;
      text-align: center;
    }
    .strength-indicator:first-child {
      text-align: left;
    }
    .strength-indicator:last-child {
      text-align: right;
    }
  }

  .strength-label {
    display: block;
    width: 100%;
    margin-bottom: $base-spacing * 4;
    text-align: left;
    font-weight: bold;
    @apply text-lg;
  }
  .strength-label-value {
    color: $blue-text;
  }
</style>
