<template>
  <div class="qty-input-wrap" aria-label="Amount of candles you want to make.">
    <button
      ref="decreaseBtn"
      :aria-label="`Decrease quantity, current amount: ${modelValue}`"
      class="qty-button qty-decrease"
      @click="decrease"
    >
      <SvgIcon icon="minus" class="qty-icon" />
    </button>
    <Input
      ref="inputComponent"
      class="qty-input [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
      type="text"
      min="0"
      :value="modelValue"
      :max="max"
      inputmode="decimal"
      pattern="[0-9]*"
      @keydown="keydownListener"
      @keyup.prevent="keyupListener"
      @input="updateValue"
      @focus="hasFocus = true"
      @blur="validate"
    />
    <button
      ref="increaseBtn"
      :aria-label="`Increase quantity, current amount: ${modelValue}`"
      class="qty-button qty-increase"
      @click="increase"
    >
      <SvgIcon icon="plus" class="qty-icon" />
    </button>
  </div>
</template>

<script setup lang="ts">
  import Input from "~/components/Input/index.vue"
  import { useNotificationStore } from "~/pinia/notifications"
  const notificationStore = useNotificationStore()

  const props = withDefaults(
    defineProps<{
      modelValue: number
      max?: number
      allowDecimals?: boolean
    }>(),
    {
      allowDecimals: false,
      max: 1999,
    },
  )

  const increaseBtn = ref<HTMLButtonElement>()
  const decreaseBtn = ref<HTMLButtonElement>()
  const inputComponent: Ref<InstanceType<typeof Input> | null> = ref(null)

  const hasFocus = ref(false)

  const emit = defineEmits<{
    "update:modelValue": [value: number]
  }>()

  const shiftPressed = ref(false)
  const altPressed = ref(false)

  const updateValue = (e: Event): void => {
    const { value } = e.target as HTMLInputElement

    const result = parseFloat(value.replace(",", "."))

    if (isNaN(result)) {
      emit("update:modelValue", 0)
    } else {
      emit("update:modelValue", result)
    }
  }

  const keydownListener = (e: KeyboardEvent) => {
    const decimalShift = props.allowDecimals && e.altKey

    if (e.key === "ArrowUp" && !e.shiftKey && !decimalShift) {
      e.preventDefault()
      keyIncrease(1)
    } else if (e.key === "ArrowDown" && !e.shiftKey && !decimalShift) {
      e.preventDefault()
      keyDecrease(1)
    } else if (e.key === "ArrowUp" && e.shiftKey) {
      e.preventDefault()
      keyIncrease(10)
    } else if (e.key === "ArrowDown" && e.shiftKey) {
      e.preventDefault()
      keyDecrease(10)
    } else if (e.key === "ArrowUp" && e.altKey && decimalShift) {
      e.preventDefault()
      keyIncrease(0.5)
    } else if (e.key === "ArrowDown" && e.altKey && decimalShift) {
      e.preventDefault()
      keyDecrease(0.5)
    } else if (e.code === "Space") {
      e.preventDefault()
      if (e.target === increaseBtn.value) {
        if (props.allowDecimals) {
          keyIncrease(e.shiftKey ? 10 : decimalShift ? 0.5 : 1)
        } else {
          keyIncrease(e.shiftKey ? 10 : 1)
        }
      }
      if (e.target === decreaseBtn.value) {
        if (props.allowDecimals) {
          keyDecrease(e.shiftKey ? 10 : decimalShift ? 0.5 : 1)
        } else {
          keyDecrease(e.shiftKey ? 10 : 1)
        }
      }
    }
  }
  const keyupListener = (e: KeyboardEvent) => {
    if (e.key === "Shift") {
      shiftPressed.value = false
    }
    if (e.key === "Alt") {
      altPressed.value = false
    }
  }

  const increase = (e: MouseEvent) => {
    const shift = e.shiftKey ? 10 : props.allowDecimals && e.altKey ? 0.5 : 1
    emit("update:modelValue", props.modelValue + shift)
  }

  const keyIncrease = (amount: number) => {
    notificationStore.announce((props.modelValue + amount).toString())
    emit("update:modelValue", props.modelValue + amount)
  }

  const decrease = (e: MouseEvent) => {
    const shift = e.shiftKey ? 10 : props.allowDecimals && e.altKey ? 0.5 : 1
    if (props.modelValue - shift < 0) {
      emit("update:modelValue", 0)
    } else {
      emit("update:modelValue", props.modelValue - shift)
    }
  }
  const keyDecrease = (amount: number) => {
    notificationStore.announce((props.modelValue - amount).toString())

    if (props.modelValue - amount < 0) {
      emit("update:modelValue", 0)
    } else {
      emit("update:modelValue", props.modelValue - amount)
    }
  }

  const validate = () => {
    hasFocus.value = false
    const decimalAmount = props.modelValue.toString().split(".")[1]

    if (props.modelValue > props.max) {
      emit("update:modelValue", props.max)
    } else if (!props.allowDecimals) {
      emit("update:modelValue", Math.round(props.modelValue))
    } else if (decimalAmount && decimalAmount.length > 2) {
      emit("update:modelValue", parseFloat(props.modelValue.toFixed(2)))
    } else {
      emit("update:modelValue", props.modelValue)
    }
  }
</script>

<style scoped lang="scss">
  .qty-input-wrap {
    display: grid;
    align-items: center;
    grid-gap: $base-spacing * 2;
    grid-template-columns: auto 1fr auto;
    margin-bottom: $space-m;
  }

  .qty-input {
    text-align: center;
    margin-bottom: 0;
  }

  .qty-icon {
    width: $base-spacing * 6;
    height: $base-spacing * 6;
    color: $blue-shape;
    display: block;
  }

  .qty-button {
    background: none;
    border: none;
    background: $blue-tint;
    height: $base-spacing * 12;
    width: $base-spacing * 12;
    border-radius: $base-spacing * 12;
    padding: $base-spacing * 3;
    &:hover {
      background: $blue-100;
    }
  }
</style>
